<template>
  <div class="login">
    <div class="card card-shadow">
      <form class="card-body" @submit.prevent="submitLoginForm">
        <div class="mt-3 mb-4 d-flex align-items-center">
          <img src="../assets/logo.png" alt="logo" class="logo me-3">
          <h4 class="card-title mb-0">登入 AJA Time Tracking</h4>
        </div>
        <div class="card-text">
          <div v-show="loginFailed" class="mb-3">
            <div class="alert alert-danger mb-0 d-flex align-items-center" role="alert">
              <span class="me-12px"><i class="bi bi-exclamation-circle"></i></span>
              <span v-if="loginFailed === 'google_not_aja_account'">請使用你在 AJA 的 Google 帳戶登入</span>
              <span v-else-if="loginFailed === 'google_account_not_exists'">查無此人 😥
              &nbsp;如果你是剛來到 AJA 的新夥伴，請找總監 James 幫你建立帳戶</span>
              <span v-else>帳號或密碼輸入錯誤</span>
            </div>
          </div>
          <div class="signin-with-google">
            <!-- eslint-disable max-len -->
            <div
              id="g_id_onload"
              data-client_id="1051216439259-0tope024ns8c9723omoi7r5ollvh9q8f.apps.googleusercontent.com"
              data-context="signin"
              data-ux_mode="popup"
              data-login_uri="https://timetracking.aja.com.tw/api.php"
              data-auto_prompt="false"
            ></div>
            <div
              class="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-locale="zh-TW"
              data-logo_alignment="center"
              data-width="256"
            ></div>
            <!-- eslint-enable max-len -->
          </div>
          <div class="mb-3">
            <label for="username" class="form-label">AJA Email</label>
            <div class="input-group">
              <input type="text" class="form-control" id="username" name="username" required>
              <span class="input-group-text">@aja.com.tw</span>
            </div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">密碼</label>
            <input type="password" class="form-control" id="password" name="password" required>
          </div>
        </div>
        <div class="mt-4 mb-3">
          <button type="submit" class="btn btn-primary w-100">登入</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { fetchApi } from '../utils/index';

export default {
  name: 'Login',
  data() {
    return {
      loginFailed: false,
    };
  },
  methods: {
    submitLoginForm() {
      const formElement = document.querySelector('form.card-body');
      const formData = new FormData(formElement);
      formData.set('username', `${formData.get('username')}@aja.com.tw`);
      fetchApi('login', Object.fromEntries(formData))
        .then((data) => {
          if (data.status === 0) {
            this.$router.push('/');
          } else {
            this.loginFailed = true;
          }
        })
        .catch(() => {
          this.loginFailed = true;
        });
    },
  },
  mounted() {
    if (this.$route.query.error) this.loginFailed = this.$route.query.error;
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', 'https://accounts.google.com/gsi/client');
    document.body.appendChild(scriptElement);
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap-grid";
.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  .card {
    width: 90vw;
    @include media-breakpoint-up(sm) {
      width: 500px;
    }
  }
}
img.logo {
  width: 36px;
  height: 36px;
}
.signin-with-google {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 1.5rem;
}
.g_id_signin {
  display: flex;
  justify-content: center;
}
</style>
